var site = site || {};
site.productView = site.productView || {};

site.productView.InvBISList = function($listNode) {
  var that = {
    $listNode : $listNode
  };
  that.productId = that.$listNode.attr("data-product-id");
  
  that.$listNode.find('.product__inventory-status').on("inventory_status_stored", function(e) {
    that.updateBISStatus();
    e.stopPropagation();
  });

  that.updateBISStatus = function() {
    var skuId = that.$listNode.find('.product__inventory-status').attr("data-sku-id");
    var skuData = $(document).data(skuId);
    if (!skuData || !skuData.INVENTORY_STATUS) return null;
    if(skuData.INVENTORY_STATUS == 2){
      $('.product__bis', that.$listNode).removeClass('hidden');
      if(!$('.product__bis', that.$listNode).hasClass('bis-processed')){
        that.setWaitlistLink(skuData, $listNode, 'BIS', 'product__bis a');
        $('.product__bis', that.$listNode).addClass('bis-processed');
      }
    }else{
      $('.product__bis', that.$listNode).addClass('hidden');
    }
  };

  that.updateBISStatus();

  that.$listNode.on('sku:select', function(e, skuData) {
    that.$listNode.attr("data-sku-id", skuData.SKU_ID);
    that.updateBISStatus();
    e.stopPropagation();
  });

  that.setWaitlistLink = function(sku, $listNode, req_type, req_link) {
    // set waitlist link
    $('.'+req_link, $listNode).on("click",function(e, link){
        e.preventDefault();
        $(link).unbind();
        var sku_id = sku.SKU_ID;
        var sku_base_id = sku_id.replace("SKU", "");
        var file = '/waitlist/signup.tmpl?SKU_BASE_ID='+sku_base_id+'&REQUEST_TYPE='+req_type;
        var options = {
        includeBackground: false,
        includeCloseLink: true,
        hideOnClickOutside: true,
        cssStyle: {border:'1px solid #5dba98', width: 'auto', height: 'auto', padding: '0'}
        };
        options.content = '<iframe style="width="343px" height="185px" scrolling="no" frameborder="0" src="'+file+'"></iframe>';
        generic.overlay.launch(options);
    });
  };

  return that;
};

(function($) {
  Drupal.behaviors.ELB_invBISList = {
    attach: function(context, settings) {
      $('.product-full__description, .product_brief').each( function() {
        var invBISList = site.productView.InvBISList($(this));
      });
    }
  };
})(jQuery);
